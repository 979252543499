import { gql } from '@apollo/client';
import { SPOT_ALERT_INFO, USER_LOCATION_INFO, USER_LONG_INFO } from './user-fragments';

export const SET_LOCATION_MUTATION = gql`
    mutation updateUser($id: ID!, $latitude: Float, $longitude: Float, $state: String, $city: String) {
        updateUser(id: $id, latitude: $latitude, longitude: $longitude, state: $state, city: $city) {
            id
            ...UserLocationInfo
        }
    }
    ${USER_LOCATION_INFO}
`;

export const GET_INVITE_URL = gql`
    mutation getInviteUrl($inviteType: InvitedAs) {
        getInviteUrl(inviteType: $inviteType)
    }
`;

export const USER_INVITE_DATE_MUTATION = gql`
    mutation updateUser($id: ID!, $showInviteAt: ISO8601DateTime) {
        updateUser(id: $id, showInviteAt: $showInviteAt) {
            showInviteAt
        }
    }
`;

export const UPDATE_SPOT_ALERT_MUTATION = gql`
    mutation updateSpotAlert(
        $radius: Int
        $enclosureType: AlertEnclosure
        $minimumSize: Float
        $dogsAllowed: Boolean
        $active: Boolean
        $latitude: Float
        $longitude: Float
    ) {
        updateSpotAlert(
            latitude: $latitude
            longitude: $longitude
            radius: $radius
            enclosureType: $enclosureType
            minimumSize: $minimumSize
            dogsAllowed: $dogsAllowed
            active: $active
        ) {
            ...SpotAlertInfo
        }
    }
    ${SPOT_ALERT_INFO}
`;

export const UPDATE_USER_MUTATION = gql`
    mutation updateUser(
        $id: ID!
        $firstname: String
        $lastname: String
        $phoneNumber: String
        $about: String
        $aboutHost: String
        $aboutHostMore: String
        $email: String
        $firstHear: String
        $firstHearSocialMedia: String
        $referralCode: String
        $tosAgree: Boolean
        $vaccinated: Boolean
        $hostWelcomeMessageBanner: Boolean
        $hostWelcomeMessageText: String
        $hostWelcomeMessagesOn: Boolean
        $hostThankYouMessageText: String
        $hostThankYouMessagesOn: Boolean
        $hostMode: Boolean
        $avatarAws: Upload
        $gender: String
        $birthAt: ISO8601Date
        $race: String
        $instagramUsername: String
        $payoutAccountId: String
        $hostRulesAccepted: Boolean
        $rulesAccepted: Boolean
        $showGuestBannerApp: Boolean
        $showGuestBannerFacebook: Boolean
        $showGuestBannerPhoneNumber: Boolean
        $showHostBannerBookingsAutoConfirmed: Boolean
        $showHostBannerDownloadApp: Boolean
        $showHostBannerFacebook: Boolean
        $showHostBannerMembership: Boolean
        $showHostBannerOptimizeSpotSettings: Boolean
        $showHostBannerRisingSpot: Boolean
        $showHostBannerSetupCalendar: Boolean
        $showHostBannerTopSpot: Boolean
        $showHostBannerWelcomeMessage: Boolean
        $testGroups: [String!]
    ) {
        updateUser(
            id: $id
            firstname: $firstname
            lastname: $lastname
            email: $email
            phoneNumber: $phoneNumber
            about: $about
            aboutHost: $aboutHost
            aboutHostMore: $aboutHostMore
            firstHear: $firstHear
            firstHearSocialMedia: $firstHearSocialMedia
            avatarAws: $avatarAws
            tosAgree: $tosAgree
            vaccinated: $vaccinated
            hostWelcomeMessageBanner: $hostWelcomeMessageBanner
            hostWelcomeMessageText: $hostWelcomeMessageText
            hostWelcomeMessagesOn: $hostWelcomeMessagesOn
            hostThankYouMessageText: $hostThankYouMessageText
            hostThankYouMessagesOn: $hostThankYouMessagesOn
            referralCode: $referralCode
            hostMode: $hostMode
            gender: $gender
            birthAt: $birthAt
            race: $race
            instagramUsername: $instagramUsername
            payoutAccountId: $payoutAccountId
            hostRulesAccepted: $hostRulesAccepted
            rulesAccepted: $rulesAccepted
            showGuestBannerApp: $showGuestBannerApp
            showGuestBannerFacebook: $showGuestBannerFacebook
            showGuestBannerPhoneNumber: $showGuestBannerPhoneNumber
            showHostBannerBookingsAutoConfirmed: $showHostBannerBookingsAutoConfirmed
            showHostBannerDownloadApp: $showHostBannerDownloadApp
            showHostBannerFacebook: $showHostBannerFacebook
            showHostBannerMembership: $showHostBannerMembership
            showHostBannerOptimizeSpotSettings: $showHostBannerOptimizeSpotSettings
            showHostBannerRisingSpot: $showHostBannerRisingSpot
            showHostBannerSetupCalendar: $showHostBannerSetupCalendar
            showHostBannerTopSpot: $showHostBannerTopSpot
            showHostBannerWelcomeMessage: $showHostBannerWelcomeMessage
            testGroups: $testGroups
        ) {
            ...UserLongInfo
        }
    }
    ${USER_LONG_INFO}
`;

export const DELETE_USER_MUTATION = gql`
    mutation deleteUser {
        deleteUser {
            id
        }
    }
`;

export const REQ_MEMBERSHIP_MUTATION = gql`
    mutation requestMembership($spotId: ID!) {
        requestMembership(spotId: $spotId)
    }
`;

export const VIEW_HOMEPAGE_MUTATION = gql`
    mutation viewPhotoHomePage($testGroup: Boolean!) {
        viewPhotoHomePage(testGroup: $testGroup)
    }
`;

export const VIEW_HOMEPAGE_V2_MUTATION = gql`
    mutation viewHomePageV2($testGroup: Boolean!) {
        viewHomePageV2(testGroup: $testGroup)
    }
`;

export const VIEW_HOMEPAGE_V3_MUTATION = gql`
    mutation viewHomeSearchV3($testGroup: Boolean!) {
        viewHomeSearchV3(testGroup: $testGroup)
    }
`;

export const VIEW_HOME_SEARCH_MUTATION = gql`
    mutation viewSearchPageNew($testGroup: Boolean!) {
        viewSearchPageNew(testGroup: $testGroup)
    }
`;

export const VIEW_SEARCHPAGE_MUTATION = gql`
    mutation viewSearchPage($testGroup: Boolean!) {
        viewSearchPage(testGroup: $testGroup)
    }
`;

export const VIEW_SEARCHPAGE_FILTERS_MUTATION = gql`
    mutation viewSearchPageFilters($testGroup: Boolean!) {
        viewSearchPageFilters(testGroup: $testGroup)
    }
`;

export const VIEW_HOSTPAGE_MUTATION = gql`
    mutation viewHostPage($testGroup: Boolean!) {
        viewHostPage(testGroup: $testGroup)
    }
`;

export const VIEW_SEOCITY_MUTATION = gql`
    mutation viewSeoCityPage($testGroup: Boolean!) {
        viewSeoCityPage(testGroup: $testGroup)
    }
`;

export const VIEW_HOST_SIGNUP_MUTATION = gql`
    mutation viewHostSignupPage($testGroup: Boolean!) {
        viewHostSignupPage(testGroup: $testGroup)
    }
`;

export const VIEW_QUICK_BOOK_MUTATION = gql`
    mutation viewQuickBookPage($testGroup: Boolean!) {
        viewQuickBookPage(testGroup: $testGroup)
    }
`;

export const VIEW_SEARCH_SIGNUP_MUTATION = gql`
    mutation viewSearchSignUpPage($testGroup: Boolean!) {
        viewSearchSignUpPage(testGroup: $testGroup)
    }
`;

export const VIEW_SEO_FRIENDLY_SIGNUP_MUTATION = gql`
    mutation viewSeoFriendlySignUp($testGroup: Boolean!) {
        viewSeoFriendlySignUp(testGroup: $testGroup)
    }
`;

export const VIEW_CALENDAR_SIGNIN_MUTATION = gql`
    mutation viewCalendarWithSignIn($testGroup: Boolean!) {
        viewCalendarWithSignIn(testGroup: $testGroup)
    }
`;

export const VIEW_ONE_PAGE_CHECKOUT_MUTATION = gql`
    mutation viewOnePageCheckout($testGroup: Boolean!) {
        viewOnePageCheckout(testGroup: $testGroup)
    }
`;

export const VIEW_SEARCH_ALGO_2024_MUTATION = gql`
    mutation viewSearchAlgo2024($testGroup: Boolean!) {
        viewSearchAlgo2024(testGroup: $testGroup)
    }
`;

export const VIEW_LISTING_AA = gql`
    mutation viewFrontTest($testGroup: Boolean!) {
        frontTest(testGroup: $testGroup)
    }
`;

export const VIEW_SPOT_TOP_BAR_MUTATION = gql`
    mutation viewSpotTopBar($testGroup: Boolean!, $sessionUuid: String) {
        viewSpotTopBar(testGroup: $testGroup, sessionUuid: $sessionUuid)
    }
`;

export const VIEW_TOP_BAR_SIGNUP_MUTATION = gql`
    mutation viewFrontTopBarSignup($testGroup: Boolean!, $sessionUuid: String) {
        viewFrontTopBarSignup(testGroup: $testGroup, sessionUuid: $sessionUuid)
    }
`;

export const VIEW_AUTH_SPOT_CONTENT = gql`
    mutation viewAuthForSpotContent($testGroup: Boolean!) {
        viewAuthForSpotContent(testGroup: $testGroup)
    }
`;

export const VIEW_GUEST_SIGNUP_PHONE = gql`
    mutation viewFrontGuestSignupPhone($testGroup: Boolean!, $sessionUuid: String) {
        viewFrontGuestSignupPhone(testGroup: $testGroup, sessionUuid: $sessionUuid)
    }
`;

export const VIEW_FILTER_AUTH_4954 = gql`
    mutation viewFrontFilterListingsAuth($testGroup: Boolean!) {
        viewFrontFilterListingsAuth(testGroup: $testGroup)
    }
`;

export const VIEW_SEO_POPUP_4936 = gql`
    mutation viewFrontSeoPopup4936($testGroup: Boolean!) {
        viewFrontSeoPopup4936(testGroup: $testGroup)
    }
`;

export const VIEW_NO_SOCIAL_SIGNUP_MUTATION = gql`
    mutation viewNoSocialSignup($testGroup: Boolean!, $sessionUuid: String) {
        viewNoSocialSignup(testGroup: $testGroup, sessionUuid: $sessionUuid)
    }
`;

export const VIEW_EMAIL_ONLY_SIGNUP_MUTATION = gql`
    mutation viewFrontEmailOnlySignup($testGroup: Boolean!, $sessionUuid: String) {
        viewFrontEmailOnlySignup(testGroup: $testGroup, sessionUuid: $sessionUuid)
    }
`;

export const VIEW_APP_DOWNLOAD_PROMPT_MUTATION = gql`
    mutation viewAppDownloadPrompt($testGroup: Boolean!, $sessionUuid: String) {
        viewAppDownloadPrompt(testGroup: $testGroup, sessionUuid: $sessionUuid)
    }
`;

export const VIEW_MULTI_PAY_METHOD_MUTATION = gql`
    mutation viewFrontMultiPayMethod($testGroup: Boolean!, $sessionUuid: String) {
        viewFrontMultiPayMethod(testGroup: $testGroup, sessionUuid: $sessionUuid)
    }
`;

export const VIEW_GUEST_HERO_SIGNUP_MUTATION = gql`
    mutation viewGuestHeroSignup($testGroup: Boolean!, $sessionUuid: String) {
        viewGuestHeroSignup(testGroup: $testGroup, sessionUuid: $sessionUuid)
    }
`;

export const VIEW_HAPPY_DOG_MUTATION = gql`
    mutation viewHappyDogTest($testGroup: Boolean!, $sessionUuid: String) {
        viewHappyDogTest(testGroup: $testGroup, sessionUuid: $sessionUuid)
    }
`;

export const VIEW_HOST_SPOT_DESCRIPTION_MUTATION = gql`
    mutation viewHostSpotDescription($testGroup: Boolean!, $sessionUuid: String) {
        viewHostSpotDescription(testGroup: $testGroup, sessionUuid: $sessionUuid)
    }
`;

export const VIEW_HOST_SPOT_INSTRUCTIONS_MUTATION = gql`
    mutation viewHostSpotInstruction($testGroup: Boolean!, $sessionUuid: String) {
        viewHostSpotInstruction(testGroup: $testGroup, sessionUuid: $sessionUuid)
    }
`;

export const VIEW_HOST_SPOT_DISTRACTIONS_MUTATION = gql`
    mutation viewHostSpotDistraction($testGroup: Boolean!, $sessionUuid: String) {
        viewHostSpotDistraction(testGroup: $testGroup, sessionUuid: $sessionUuid)
    }
`;

export const SIGNUP_HOST = gql`
    mutation signUp(
        $email: String!
        $firstname: String!
        $lastname: String!
        $password: String!
        $phoneNumber: String
        $uuid: String
        $invitedAs: InvitedAs
        $invitedByUuid: String
        $utmSource: String
        $utmMedium: String
        $utmCampaign: String
        $createdFrom: CreatedFrom
        $createdPlatform: CreatedPlatform
    ) {
        signUp(
            email: $email
            firstname: $firstname
            lastname: $lastname
            password: $password
            phoneNumber: $phoneNumber
            uuid: $uuid
            invitedAs: $invitedAs
            invitedByUuid: $invitedByUuid
            utmSource: $utmSource
            utmMedium: $utmMedium
            utmCampaign: $utmCampaign
            createdFrom: $createdFrom
            createdPlatform: $createdPlatform
        ) {
            id
            email
            phoneNumber
            testGroups
        }
    }
`;

export const SIGNUP_V2 = gql`
    mutation signUpV2(
        $email: String!
        $firstname: String
        $lastname: String
        $password: String
        $phoneNumber: String
        $uuid: String
        $invitedAs: InvitedAs
        $invitedByUuid: String
        $utmSource: String
        $utmMedium: String
        $utmCampaign: String
        $createdFrom: CreatedFrom
        $createdPlatform: CreatedPlatform
    ) {
        signUpV2(
            email: $email
            firstname: $firstname
            lastname: $lastname
            password: $password
            phoneNumber: $phoneNumber
            uuid: $uuid
            invitedAs: $invitedAs
            invitedByUuid: $invitedByUuid
            utmSource: $utmSource
            utmMedium: $utmMedium
            utmCampaign: $utmCampaign
            createdFrom: $createdFrom
            createdPlatform: $createdPlatform
        ) {
            id
            email
            phoneNumber
            testGroups
        }
    }
`;

export const USER_ONBOARD = gql`
    mutation onboard($firstname: String!, $lastname: String!, $password: String) {
        onboard(firstname: $firstname, lastname: $lastname, password: $password) {
            id
            status
        }
    }
`;

export const REMOVE_GOOGLE_CALENDAR = gql`
    mutation removeGoogleCalendar {
        removeGoogleCalendar
    }
`;
